import React from "react";
import { Container, Typography } from "@mui/material";
import AccordionPanel from "../../../markup/AccordionPanel";
import feature1 from "./1_MF_Booking_3.mp4";
import feature2 from "./2_MF_Schedule.mp4";
import feature3 from "./3_ MF_Masters Profile and Portfolio.mp4";
import feature4 from "./4_MF_Statistic Info.mp4";
import feature5 from "./5_MF_Masters Services.mp4";
import feature6 from "./6_MF_Workshop and Blogs.mp4";
import feature7 from "./7_MF_Workshop and Blogs.mp4";
import bg from "./MF_Background.jpg";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";

enum MasterFeature {
  FEATURE_1 = "FEATURE_1",
  FEATURE_2 = "FEATURE_2",
  FEATURE_3 = "FEATURE_3",
  FEATURE_4 = "FEATURE_4",
  FEATURE_5 = "FEATURE_5",
  FEATURE_6 = "FEATURE_6",
  FEATURE_7 = "FEATURE_7",
}

const MasterFeaturesFrame = () => {
  const isLG = useBiggerThanLG();
  const [expanded, setExpanded] = React.useState<string>(MasterFeature.FEATURE_1);
  const {t} = useTranslation();

  return (
    <div style={{paddingTop: isLG ? "3rem" : "0rem", paddingBottom: "3rem", zIndex: 2, backgroundColor: "white"}}>
      <Container component="div" maxWidth="lg" style={{display: isLG ? "block" : "none"}}>
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <div style={{textAlign: "center"}}>
            <Typography variant={isLG ? "h2" : "h5"} style={{fontWeight: 700}}>
              {t('artist-features.title')}
            </Typography>
          </div>
        </div>
      </Container>
      <div style={{width: "100%", position: "absolute", zIndex: -1}}>
        <Container component="div" maxWidth="lg">
          <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: isLG ? "row" : "column"}}>
              <div style={{
                width: isLG ? "40%" : "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}>
                <img src={bg} style={isLG ? ({width: "120%", marginTop: "-3rem"}) : ({height: "28rem"})}/>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container component="div" maxWidth="lg">
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <div style={{width: "100%", height: "100%", display: "flex", flexDirection: isLG ? "row" : "column"}}>
            <div style={{
              width: isLG ? "40%" : "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
              {expanded === MasterFeature.FEATURE_1 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature1} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_2 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature2} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_3 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature3} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_4 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature4} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_5 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature5} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_6 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature6} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
              {expanded === MasterFeature.FEATURE_7 ? (
                <video autoPlay muted loop style={isLG
                  ? ({
                    width: "120%",
                    marginTop: "-3rem",
                    zIndex: "-1",
                  })
                  : ({height: "28rem"})}>
                  <source src={feature7} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
            <div style={{width: isLG ? "60%" : "100%", paddingTop: isLG ? "4rem" : "0.5rem"}}>
              <AccordionPanel
                content={[
                  {
                    title: t('artist-features.f1.title'),
                    panelName: MasterFeature.FEATURE_1,
                    description: t('artist-features.f1.body'),
                  },
                  {
                    title: t('artist-features.f2.title'),
                    panelName: MasterFeature.FEATURE_2,
                    description: t('artist-features.f2.body'),
                  },
                  {
                    title: t('artist-features.f3.title'),
                    panelName: MasterFeature.FEATURE_3,
                    description: t('artist-features.f3.body'),
                  },
                  {
                    title: t('artist-features.f4.title'),
                    panelName: MasterFeature.FEATURE_4,
                    description: t('artist-features.f4.body'),
                  },
                  {
                    title: t('artist-features.f5.title'),
                    panelName: MasterFeature.FEATURE_5,
                    description: t('artist-features.f5.body'),
                  },
                  {
                    title: t('artist-features.f6.title'),
                    panelName: MasterFeature.FEATURE_6,
                    description: t('artist-features.f6.body'),
                  },
                  {
                    title: t('artist-features.f7.title'),
                    panelName: MasterFeature.FEATURE_7,
                    description: t('artist-features.f7.body'),
                  },
                ]}
                selectedPanel={expanded}
                setSelectedPanel={setExpanded}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MasterFeaturesFrame;