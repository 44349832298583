import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import BrowserRoute from "../../../constants/browser-routes";
import HomePage from "../../view/HomePage";
import PresentationPage from "../../view/PresentationPage";

interface ApplicationRoutesProps {

}

const HomeRedirect = () => <Navigate to={BrowserRoute.ROOT} replace/>;

const ApplicationRoutes: FC<ApplicationRoutesProps> = () => (
  <Routes>
    <Route path={BrowserRoute.ROOT} element={<App/>}>
      {/*<Route index element={<HomeRedirect/>}/>*/}
      <Route index element={<HomePage/>}/>
      {/*<Route path={BrowserRoute.ABOUT} element={<AboutPage/>}/>*/}
      {/*<Route path={BrowserRoute.FAQs} element={<FAQsPage/>}/>*/}
      <Route
        path={BrowserRoute.PRESENTATION}
        element={<PresentationPage/>}
      />
      <Route path="*" element={<HomeRedirect/>}/>
    </Route>
  </Routes>
);

export default ApplicationRoutes;
