import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface PresentationPageProps {

}

const PresentationPage: FC<PresentationPageProps> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.location.replace("https://beauty-club-photos.s3.amazonaws.com/presentation/GLAMLAB_AI_Platform.pdf");
  }, [""]);
  return (
    <div/>
  );
};

export default PresentationPage;
