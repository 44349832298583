import React, { FC } from "react";
import { Container, Typography } from "@mui/material";
import logo from "../../../../glam_logo.svg";
import { useTranslation } from "react-i18next";
import inst from "./Instagram.svg";
import linkedIn from "./Linkedin.svg";
import apple from "../HeaderFrame/DownloadApple.svg";
import google from "../HeaderFrame/DownloadGoogle.svg";
import { useBiggerThanLG } from "../../../../hooks";
import BrowserRoutes from "../../../../constants/browser-routes";
import { LandingPlatform, LandingUserType } from "../../../../service/LandingFeedbackService";

interface FooterFrameProps {
  handleClickOpen: (platform: LandingPlatform, userType?: LandingUserType) => () => void;
}

const FooterFrameNoLinks: FC<FooterFrameProps> = ({
  handleClickOpen,
}) => {
  const isLG = useBiggerThanLG();
  const {t} = useTranslation();
  return (
    <Container component="div" maxWidth="lg" style={{
      display: "flex",
      flexDirection: isLG ? "row" : "column",
      marginTop: isLG ? "3rem" : "1rem",
      marginBottom: "3rem",
      justifyContent: "space-between",
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: isLG ? "30rem" : "100%",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          width: "13rem",
          alignItems: "center",
        }}>
          <img src={logo}/>
          <Typography variant="caption" noWrap style={{
            color: "#298CA2",
            paddingLeft: "1rem",
            // fontFamily: 'Urbanist',
          }}>
            {t("app.name")}
          </Typography>
        </div>
        <Typography variant={isLG ? "subtitle1" : "subtitle2"} style={{marginTop: isLG ? "2rem" : "1rem"}}>
          {t("footer.description")}
        </Typography>
      </div>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <div style={{display: "flex", flexDirection: isLG ? "row-reverse" : "row"}}>
          <a href={`mailto:${t("app.email")}`} style={{textDecoration: "none", color: "black"}}>
            <Typography variant={isLG ? "subtitle1" : "subtitle2"} style={{marginTop: "1rem"}}>
              {t("app.email")}
            </Typography>
          </a>
        </div>
        <div style={{display: "flex", flexDirection: isLG ? "row-reverse" : "row", marginTop: "1rem"}}>
          <a href={BrowserRoutes.INSTAGRAM}>
            <img src={inst} style={{height: "3rem", marginRight: "0.5rem"}}/>
          </a>
          <a href={BrowserRoutes.LINKED_IN}>
            <img src={linkedIn} style={{height: "3rem", marginRight: "0.5rem"}}/>
          </a>
        </div>
        <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>
          <img src={apple}
               style={{height: isLG ? "4rem" : "2.5rem", marginRight: "1rem", cursor: 'pointer'}}
               onClick={handleClickOpen(LandingPlatform.IOS)}
          />
          <img src={google}
               style={{height: isLG ? "4rem" : "2.5rem", marginRight: "1rem", cursor: 'pointer'}}
               onClick={handleClickOpen(LandingPlatform.ANDROID)}
          />
        </div>
      </div>
    </Container>
  );
};

export default FooterFrameNoLinks;