import React, { FC } from "react";
import { Container, Typography } from "@mui/material";
import clientMock from "./Client_app_mock.png";
import artistMock from "./Artist_app_mock.png";
import "./ChooseApplicationFrame.css";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

interface ChooseApplicationFrameProps {
}

const ChooseApplicationFrame: FC<ChooseApplicationFrameProps> = () => {
  const isLG = useBiggerThanLG();
  const {t} = useTranslation();
  const {describeClientRef, describeMasterRef, aboutRef} = useOutletContext<OutletContextProps>();

  return (
    <>
      <div style={{display: isLG ? "block" : "none"}} ref={aboutRef}>
        <div
          style={{
            display: "flex",
            flexDirection: isLG ? "row" : "column",
            width: "100%",
            position: "absolute",
            height: "35rem",
            zIndex: -1,
          }}>
          <div style={{
            width: isLG ? "50%" : "100%",
            height: isLG ? "unset" : "7rem",
            background: "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
          }}/>
          <div style={{width: isLG ? "50%" : "100%", height: isLG ? "unset" : "7rem", background: "#CEEAF0"}}/>
        </div>
        <Container component="div" maxWidth="lg" style={{height: "35rem"}}>
          <div className={isLG ? "application-selector" : ""}>
            <div className={isLG ? "application-selector__left" : ""}
                 style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}
                 onClick={() => describeClientRef.current?.scrollIntoView({behavior: "smooth"})}
            >
              <div style={{position: isLG ? "absolute" : "unset", width: "25rem", padding: "1.5rem", color: "white"}}>
                <Typography variant={isLG ? "h1" : "h4"}>
                  {t("choose-app.client-header")}
                </Typography>
                <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: "3rem"}}>
                  {t("choose-app.client-body")}
                </Typography>
                <Typography
                  variant={isLG ? "h2" : "h5"}
                  style={{
                    paddingTop: "6rem",
                  }}
                >
                  {t("choose-app.learn-more")}
                </Typography>
              </div>
              <div></div>
              <img src={clientMock} style={{height: isLG ? "35rem" : "5rem"}} className={isLG ? "client-image" : ""}/>
            </div>
            <div className={isLG ? "application-selector__right" : ""}
                 style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}
                 onClick={() => describeMasterRef.current?.scrollIntoView({behavior: "smooth"})}
            >
              <div style={{position: "absolute", width: "25rem", padding: "1.5rem"}}>
                <Typography variant={isLG ? "h1" : "h4"}>
                  {t("choose-app.artist-header")}
                </Typography>
                <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: "3rem"}}>
                  {t("choose-app.artist-body")}
                </Typography>
                <Typography
                  variant={isLG ? "h2" : "h5"}
                  style={{
                    paddingTop: "6rem",
                  }}
                >
                  {t("choose-app.learn-more")}
                </Typography>
              </div>
              <div></div>
              <img src={artistMock} style={{height: "35rem"}} className={isLG ? "artist-image" : ""}/>
            </div>
          </div>
        </Container>
      </div>
      <Container component="div" maxWidth="lg" style={{
        display: isLG ? "none" : "flex",
        background: "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        color: "white",
      }}>
        <div
          style={{display: "flex", flexDirection: "row", width: "100%"}}
          onClick={() => describeClientRef.current?.scrollIntoView({behavior: "smooth"})}
        >
          <div style={{display: "flex", flexDirection: "column", maxWidth: "25rem"}}>
            <Typography variant={isLG ? "h1" : "h4"}>
              {t("choose-app.client-header")}
            </Typography>
            <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: "1rem"}}>
              {t("choose-app.client-body")}
            </Typography>
            <Typography variant={isLG ? "h2" : "h5"} style={{paddingTop: "2rem"}}>
              {t("choose-app.learn-more")}
            </Typography>
          </div>
          <img src={clientMock} style={{height: "10rem"}}/>
        </div>
      </Container>
      <Container component="div" maxWidth="lg" style={{
        display: isLG ? "none" : "flex",
        background: "#CEEAF0",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        marginBottom: "1rem",
      }}>
        <div
          style={{display: "flex", flexDirection: "row", width: "100%", textAlign: "right"}}
          onClick={() => describeMasterRef.current?.scrollIntoView({behavior: "smooth"})}
        >
          <img src={artistMock} style={{height: "10rem"}}/>
          <div style={{display: "flex", flexDirection: "column", maxWidth: "25rem"}}>
            <Typography variant={isLG ? "h1" : "h4"}>
              {t("choose-app.artist-header")}
            </Typography>
            <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: "1rem"}}>
              {t("choose-app.artist-body")}
            </Typography>
            <Typography variant={isLG ? "h2" : "h5"} style={{paddingTop: "2rem"}}>
              {t("choose-app.learn-more")}
            </Typography>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ChooseApplicationFrame;