export const load = <T>(key: string): T | undefined => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
};

export const save = (key: string, data: any) => localStorage.setItem(key, JSON.stringify(data));

export const remove = (key: string) => localStorage.removeItem(key);
