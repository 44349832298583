import { Container, Typography } from "@mui/material";
import tableCorner from "./PhoneOnTable.png";
import React, { FC } from "react";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

interface DescribeClientFrameProps {
}

const DescribeClientFrame: FC<DescribeClientFrameProps> = () => {
  const isLG = useBiggerThanLG();
  const {t} = useTranslation();
  const {describeClientRef} = useOutletContext<OutletContextProps>();
  return (
    <div style={{paddingTop: isLG ? "3rem" : 0, zIndex: 10}} ref={describeClientRef}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          position: "absolute",
          height: isLG ? "45rem" : "15rem",
          zIndex: -1,
        }}>
        <div style={{
          width: isLG ? "calc(50% - 32rem)" : "0%",
          background: "linear-gradient(180deg, #E6E6E6 0%, #D7D7D7 100%)",
        }}/>
        <div style={{
          width: isLG ? "calc(50% + 32rem)" : "100%",
          background: "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
        }}/>
      </div>
      <Container component="div" maxWidth="lg"
                 style={{height: isLG ? "45rem" : "15rem", textAlign: isLG ? "unset" : "right"}}>
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row"}}>
          <div style={{width: isLG ? "60%" : "25%"}}>
            <img src={tableCorner} style={{height: isLG ? "45rem" : "15rem", marginLeft: "-10rem"}}/>
          </div>
          <div style={{width: isLG ? "40%" : "75%", display: "flex", flexDirection: "column", color: "white"}}>
            <Typography variant={isLG ? "h2" : "h5"} style={{paddingTop: isLG ? "8rem" : "1rem"}}>
              {t("describe-client.header")}
            </Typography>
            <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: isLG ? "3rem" : "0.5rem"}}>
              {t("describe-client.body")}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DescribeClientFrame;