import React, { useState } from "react";
import pattern from "./BluredPattern.png";
import { Container, Typography } from "@mui/material";
import "./KeepInTouchFrame.css";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import inst from "./Instagram.svg";
import linkedIn from "./Linkedin.svg";
import check from "./Check.svg";
import { useBiggerThanLG } from "../../../../hooks";
import LandingFeedbackService from "../../../../service/LandingFeedbackService";
import { useTranslation } from "react-i18next";
import isEmailValid from "../../../../utils/email-utils";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";
import BrowserRoutes from "../../../../constants/browser-routes";

const SendButton = styled(Button)<ButtonProps>(({theme}) => ({
  borderRadius: "2rem",
  color: "black",
  margin: "0 1rem",
  fontSize: "1.25rem",
  width: "15rem",
  backgroundColor: "white",
}));

const KeepInTouchFrame = () => {
  const {t} = useTranslation();
  const isLG = useBiggerThanLG();
  const {keepInTouchRef} = useOutletContext<OutletContextProps>();
  const [isMessageSent, setMessageSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>();
  const [feedback, setFeedback] = useState<string | undefined>();
  const [isEmailTouched, setEmailTouched] = useState(false);
  const isEmailFieldValid = isEmailValid(email);
  let onSendClick = () => {
    if (email && feedback) {
      LandingFeedbackService.sendFeedback({
        email,
        feedback,
      });
      setMessageSent(true);
    }
  };
  return (
    <div ref={keepInTouchRef}>
      <div style={{
        display: isLG ? "flex" : "none",
        flexDirection: "row",
        width: "100%",
        position: "absolute",
        background: "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
        height: isLG ? "45rem" : "unset",
      }}>
        <div style={{
          width: "50%",
          display: "flex",
          justifyContent: "end",
        }}>
          <img src={pattern}
               style={{
                 height: "60rem",
                 marginTop: "-10rem",
                 marginRight: "0rem",
               }}/>
        </div>
        <div style={{
          width: "50%",
          display: "flex",
          justifyContent: "start",
        }}>
          <img src={pattern}
               style={{
                 height: "60rem",
                 marginTop: "-10rem",
                 marginLeft: "20rem",
               }}/>
        </div>
      </div>
      <Container component="div" maxWidth="lg" style={{
        height: isLG ? "45rem" : "unset",
        position: "relative",
        zIndex: 2,
        background: isLG ? "unset" : "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
      }}>
        <div style={{display: "flex", flexDirection: "column", color: "white", justifyContent: "center", zIndex: 3}}>
          <Typography variant={isLG ? "h2" : "h5"} style={{textAlign: "center", paddingTop: isLG ? "4rem" : "1rem"}}>
            {t("keep-in-touch.header")}
          </Typography>
          <div style={{
            display: isMessageSent ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            height: "21rem",
          }}>
            <img src={check} style={{padding: "3rem"}}/>
            <Typography variant={isLG ? "h2" : "h5"} style={{textAlign: "center"}}>
              {t("keep-in-touch.thank-you")}
            </Typography>
            <Typography variant={isLG ? "body1" : "body2"} style={{textAlign: "center"}}>
              {t("keep-in-touch.thank-you-subtitle")}
            </Typography>
          </div>
          <div style={{
            display: isMessageSent ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "21rem",
          }}>
            <input
              type="text"
              placeholder={t("keep-in-touch.email")}
              className="custom-input"
              onChange={e => setEmail(e.target.value)}
              onBlur={() => setEmailTouched(true)}
              style={{
                marginTop: isLG ? "4rem" : "1rem",
                ...(
                  isLG
                    ? ({lineHeight: "1rem"})
                    : ({fontSize: "1rem"})
                ),
              }}
            />
            <div style={{color: "#d90909", height: "1rem"}}>
              <Typography variant={"body2"}>
                {!isEmailFieldValid && isEmailTouched ? t("header.dlg.email-invalid") : ""}
              </Typography>
            </div>
            <textarea
              placeholder={t("keep-in-touch.your-suggestion")}
              className="custom-input"
              maxLength={2000}
              rows={4}
              onChange={e => setFeedback(e.target.value)}
              style={{
                marginTop: isLG ? "1.5rem" : "0.5rem",
                resize: "none",
                ...(
                  isLG
                    ? ({lineHeight: "1rem"})
                    : ({fontSize: "1rem"})
                ),
              }}
            />
            <SendButton
              variant={"contained"}
              disableElevation
              style={{
                marginTop: isLG ? "1.5rem" : "0.5rem",
              }}
              onClick={onSendClick}
              disabled={!isEmailFieldValid || !feedback}
            >
              {t("keep-in-touch.send-btn")}
            </SendButton>
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant={isLG ? "body1" : "body2"} style={{marginTop: "2rem", textAlign: "center"}}>
              {t("keep-in-touch.subscribe-to-social")}
            </Typography>
            <div style={{display: "flex", flexDirection: isLG ? "row-reverse" : "row", marginTop: "1rem"}}>
              <a href={BrowserRoutes.INSTAGRAM}>
                <img src={inst} style={{height: "3rem", marginRight: "0.5rem"}}/>
              </a>
              <a href={BrowserRoutes.LINKED_IN}>
                <img src={linkedIn} style={{height: "3rem", marginRight: "0.5rem"}}/>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default KeepInTouchFrame;