import React from "react";
import missionBG from "./MissionBG.png";
import person from "./Person.svg";
import clock from "./Clock.svg";
import star from "./Star.svg";
import { Container, Typography } from "@mui/material";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

const MissionFrame = () => {
  const isLG = useBiggerThanLG();
  const {t} = useTranslation();
  const {missionRef} = useOutletContext<OutletContextProps>();
  return (
    <div style={{height: isLG ? "47rem" : "unset"}} ref={missionRef}>
      <div style={{
        position: "absolute",
        display: isLG ? "flex" : "none",
        flexDirection: "row",
        width: "100%",
        height: "47rem",
        zIndex: -2,
      }}>
        <div style={{width: "50%", backgroundColor: "#000000"}}></div>
        <div style={{width: "50%", backgroundColor: "#797979"}}></div>
      </div>
      <div style={{
        position: "absolute",
        display: isLG ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "47rem",
        zIndex: -1,
      }}>
        <img style={{height: "47rem"}} src={missionBG}/>
      </div>
      <Container
        component="div"
        maxWidth="lg"
        style={{
          height: isLG ? "47rem" : "unset",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
          backgroundColor: isLG ? "unset" : "#797979",
        }}>
        <Typography variant={isLG ? "h1" : "h4"} style={{marginTop: isLG ? "10rem" : "2rem"}}>
          {t("mission.header")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <div style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            margin: isLG ? "4rem" : "1rem",
            maxWidth: "16rem",
          }}>
            <img src={person} style={{height: isLG ? "6rem" : "4rem"}}/>
            <Typography variant={isLG ? "body1" : "body2"} style={{marginTop: "2rem", textAlign: "center"}}>
              {t("mission.find-artist")}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            flexGrow: 2,
            flexDirection: "column",
            margin: isLG ? "4rem" : "1rem",
            maxWidth: "18rem",
          }}>
            <img src={clock} style={{height: isLG ? "6rem" : "4rem"}}/>
            <Typography variant={isLG ? "body1" : "body2"} style={{marginTop: "2rem", textAlign: "center"}}>
              {t("mission.accessability")}
            </Typography>
          </div>
          <div style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            margin: isLG ? "4rem" : "1rem",
            maxWidth: "16rem",
          }}>
            <img src={star} style={{height: isLG ? "6rem" : "4rem"}}/>
            <Typography variant={isLG ? "body1" : "body2"} style={{marginTop: "2rem", textAlign: "center"}}>
              {t("mission.reviews")}
            </Typography>
          </div>
        </div>
        <Typography variant={isLG ? "body1" : "body2"} style={{textAlign: "center"}}>
          {t("mission.footer-1")}
        </Typography>
        <Typography variant={isLG ? "body1" : "body2"} style={{textAlign: "center", paddingBottom: "2rem"}}>
          {t("mission.footer-2")}
        </Typography>
      </Container>
    </div>
  );
};

export default MissionFrame;