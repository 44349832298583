import React from "react";
import { Container, Typography } from "@mui/material";
import AccordionPanel from "../../../markup/AccordionPanel";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

enum FAQ {
  FEATURE_1 = "FEATURE_1",
  FEATURE_2 = "FEATURE_2",
  FEATURE_3 = "FEATURE_3",
  FEATURE_4 = "FEATURE_4",
  FEATURE_5 = "FEATURE_5",
  FEATURE_6 = "FEATURE_6",
  FEATURE_7 = "FEATURE_7",
  FEATURE_8 = "FEATURE_8",
  FEATURE_9 = "FEATURE_9",
  FEATURE_10 = "FEATURE_10",
}

const FaqFrame = () => {
  const isLG = useBiggerThanLG();
  const [expanded, setExpanded] = React.useState<string>(FAQ.FEATURE_1);
  const {t} = useTranslation();
  const {FAQsRef} = useOutletContext<OutletContextProps>();

  return (
    <div style={{
      paddingTop: isLG ? "3rem" : "0.5rem",
      backgroundColor: "white",
      zIndex: 2,
      paddingBottom: isLG ? "3rem" : "0.5rem",
    }} ref={FAQsRef}>
      <Container component="div" maxWidth="lg">
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <Typography
            variant={isLG ? "h2" : "h5"}
            style={{fontWeight: 700, paddingBottom: "2rem", paddingTop: "2rem", textAlign: "center"}}>
            {t("faq.title")}
          </Typography>
          <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row"}}>
            <AccordionPanel
              forseSmall
              content={[
                {
                  title: t("faq.q1"),
                  panelName: FAQ.FEATURE_1,
                  description: t("faq.a1"),
                },
                {
                  title: t("faq.q2"),
                  panelName: FAQ.FEATURE_2,
                  description: t("faq.a2"),
                },
                {
                  title: t("faq.q3"),
                  panelName: FAQ.FEATURE_3,
                  description: t("faq.a3"),
                },
                {
                  title: t("faq.q4"),
                  panelName: FAQ.FEATURE_4,
                  description: t("faq.a4"),
                },
                {
                  title: t("faq.q5"),
                  panelName: FAQ.FEATURE_5,
                  description: t("faq.a5"),
                },
                {
                  title: t("faq.q6"),
                  panelName: FAQ.FEATURE_6,
                  description: t("faq.a6"),
                },
                {
                  title: t("faq.q7"),
                  panelName: FAQ.FEATURE_7,
                  description: t("faq.a7"),
                },
                {
                  title: t("faq.q8"),
                  panelName: FAQ.FEATURE_8,
                  description: t("faq.a8"),
                },
                {
                  title: t("faq.q9"),
                  panelName: FAQ.FEATURE_9,
                  description: t("faq.a9"),
                },
                {
                  title: t("faq.q10"),
                  panelName: FAQ.FEATURE_10,
                  description: t("faq.a10"),
                },
              ]}
              selectedPanel={expanded}
              setSelectedPanel={setExpanded}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FaqFrame;