import React, { FC } from "react";
import { Container, Typography } from "@mui/material";
import tableCorner from "./DescribeMasterBG.svg";
import tableCorner2 from "./Phones_BG_SMALL.png";
import { useBiggerThanLG } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

interface DescribeMasterFrameProps {
}

const DescribeMasterFrame: FC<DescribeMasterFrameProps> = () => {
  const isLG = useBiggerThanLG();
  const {t} = useTranslation();
  const {describeMasterRef} = useOutletContext<OutletContextProps>();
  return (
    <div style={{zIndex: 1}} ref={describeMasterRef}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          position: "absolute",
          height: isLG ? "45rem" : "16rem",
          zIndex: -1,
          background: "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
        }}>
        <Container component="div" maxWidth="lg">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: isLG ? "45rem" : "16rem",
              zIndex: -1,
            }}>
            <div style={{width: "40%"}}/>
            <div style={{width: "60%"}}>
              {isLG && <img src={tableCorner} style={{marginTop: "-25rem", marginLeft: "-5rem"}}/>}
              {!isLG && <img src={tableCorner2} style={{height: "16rem", position: "absolute", right: 0}}/>}
            </div>
          </div>
        </Container>
      </div>
      <Container component="div" maxWidth="lg" style={{height: isLG ? "45rem" : "16rem"}}>
        <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row"}}>
          <div style={{width: isLG ? "40%" : "80%", display: "flex", flexDirection: "column", color: "white"}}>
            <Typography variant={isLG ? "h2" : "h5"} style={{paddingTop: isLG ? "8rem" : "1rem"}}>
              {t("describe-master.header")}
            </Typography>
            <Typography variant={isLG ? "body1" : "body2"} style={{paddingTop: isLG ? "3rem" : "0.5rem"}}>
              {t("describe-master.body")}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DescribeMasterFrame;