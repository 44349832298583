import React, { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import "./AccordionPanel.css";
import { useBiggerThanLG } from "../../../hooks";

const AccordionPanel: FC<AccordionPanelProps> = ({content, selectedPanel, setSelectedPanel, forseSmall}) => {
  const isLG = useBiggerThanLG();
  const onChange = (panelName: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) setSelectedPanel(panelName);
  };

  const isExpanded = (panelName: string) => selectedPanel === panelName;

  return (
    <div className="accordion-container">
      {content.map(({title, description, panelName}) => (
        <Accordion expanded={isExpanded(panelName)} onChange={onChange(panelName)}
                   className={`accordion-styled ${isExpanded(panelName) ? "accordion-styled-selected" : ""}`}
                   elevation={0}
                   key={title}>
          <AccordionSummary style={{padding: "0 2rem"}}>
            <Typography variant={isLG && !forseSmall ? "h3" : "h6"}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{padding: "0.5rem 2rem 2rem"}}>
            <Typography variant={isLG && !forseSmall ? "body1" : "body2"}>
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export interface AccordionContent {
  title: string;
  description: string;
  panelName: string;
}

export interface AccordionPanelProps {
  content: AccordionContent[];
  selectedPanel: string;
  setSelectedPanel: (panelName: string) => void;
  forseSmall?: boolean;
}

export default AccordionPanel;