import React, { FC, useEffect, useState } from "react";
import { AppBar, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import logo from "../../../glam_logo.svg";
import globe from "../../../Globe.svg";
import { styled } from "@mui/material/styles";
import { useOutletContext } from "react-router-dom";
import { useBiggerThanLG } from "../../../hooks";
import { load, save } from "../../../utils/local-storage-utils";
import i18n from "../../../i18n";
import { OutletContextProps } from "../App/App";

interface TopNavBarProps {
  homeRef:  React.MutableRefObject<any>;
  aboutRef:  React.MutableRefObject<any>;
  keepInTouchRef:  React.MutableRefObject<any>;
  FAQsRef:  React.MutableRefObject<any>;
  missionRef:  React.MutableRefObject<any>;
}

const HeaderButton = styled(Button)<ButtonProps>(({theme}) => ({
  borderRadius: "2rem",
  color: "black",
  margin: "0 1rem",
  fontSize: "1.25rem",
}));

const TopNavBar: FC<TopNavBarProps> = ({
  homeRef,
  aboutRef,
  keepInTouchRef,
  FAQsRef,
  missionRef,
}) => {
  const {t} = useTranslation();
  const isLG = useBiggerThanLG();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [lang, setLang] = useState<string | undefined>(load("LANG"));
  if (!lang) {
    save("LANG", "en");
    setLang("en");
  }
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (language: string) => (event: any) => {
    event.stopPropagation();
    save("LANG", language);
    setLang(language);
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" style={{
      background: "rgba(255, 255, 255, 0.37)",
      backdropFilter: "blur(8px)",
    }} elevation={0}>
      <Toolbar>
        <Container component="div" maxWidth="lg">
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              width: "13rem",
              alignItems: "center",
            }}>
              <img src={logo}/>
              <Typography variant="caption" noWrap style={{
                color: "#298CA2",
                paddingLeft: "1rem",
              }}>
                {t("app.name")}
              </Typography>
            </div>
            {isLG && <div className="top-nav-bar-links">
                <HeaderButton onClick={() => homeRef.current?.scrollIntoView({behavior: "smooth"})}>
                  {t("app.home")}
                </HeaderButton>
                <HeaderButton onClick={() => aboutRef.current?.scrollIntoView({behavior: "smooth"})}>
                  {t("app.about")}
                </HeaderButton>
                <HeaderButton onClick={() => keepInTouchRef.current?.scrollIntoView({behavior: "smooth"})}>
                  {t("app.keepInTouch")}
                </HeaderButton>
                <HeaderButton onClick={() => FAQsRef.current?.scrollIntoView({behavior: "smooth"})}>
                  {t("app.FAQs")}
                </HeaderButton>
                <HeaderButton onClick={() => missionRef.current?.scrollIntoView({behavior: "smooth"})}>
                  {t("app.mission")}
                </HeaderButton>
            </div>}
            <div style={{
              width: "13rem",
              color: "black",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}>
              <IconButton onClick={handleClick}>
                <img src={globe} style={{width: "1.5rem", height: "1.5rem"}}/>
              </IconButton>
              <Menu
                disableScrollLock
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleClose("en")}>
                  <Typography variant="body2" noWrap>English</Typography>
                </MenuItem>
                {/*<MenuItem onClick={handleClose("ru")}>
                  <Typography variant="body2" noWrap>Русский</Typography>
                </MenuItem>*/}
              </Menu>
            </div>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
