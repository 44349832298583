import React, { useState } from "react";
import "./HomePage.css";
import HeaderFrame from "./HeaderFrame";
import ChooseApplicationFrame from "./ChooseApplicationFrame";
import DescribeClientFrame from "./DescribeClientFrame";
import ClientFeaturesFrame from "./ClientFeaturesFrame";
import DescribeMasterFrame from "./DescribeMasterFrame";
import MasterFeaturesFrame from "./MasterFeaturesFrame";
import KeepInTouchFrame from "./KeepInTouchFrame";
import FaqFrame from "./FaqFrame";
import MissionFrame from "./MissionFrame";
import FooterFrame from "./FooterFrame";
import LandingFeedbackService, { LandingPlatform, LandingUserType } from "../../../service/LandingFeedbackService";
import isEmailValid from "../../../utils/email-utils";
import { Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBiggerThanLG } from "../../../hooks";
import WhatsMoreFrame from "./WhatsMoreFrame/WhatsMoreFrame";

const HomePage = () => {
  const {t} = useTranslation();
  const isLG = useBiggerThanLG();
  const [open, setOpen] = React.useState(false);
  const [platform, setPlatform] = useState<LandingPlatform | null>(null);
  const [userType, setUserType] = useState<LandingUserType | undefined>();
  const [email, setEmail] = useState<string>("");
  const [isEmailTouched, setEmailTouched] = useState(false);
  const isEmailFieldValid = isEmailValid(email);
  const isSendDisabled = !isEmailFieldValid || !userType;

  const handleClickOpen = (platform: LandingPlatform, userType?: LandingUserType) => () => {
    setPlatform(platform);
    setUserType(userType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPlatform(null);
    setUserType(undefined);
    setEmail("");
    setEmailTouched(false);
  };

  const onSubmit = () => {
    if (userType && email && platform) {
      LandingFeedbackService.subscribeToUpdates(({userType, email, platform}));
      handleClose();
    }
  };

  return (
    <>
      <HeaderFrame handleClickOpen={handleClickOpen}/>
      <ChooseApplicationFrame/>
      <DescribeClientFrame/>
      <ClientFeaturesFrame/>
      <DescribeMasterFrame/>
      <MasterFeaturesFrame/>
      <KeepInTouchFrame/>
      <FaqFrame/>
      <MissionFrame/>
      <FooterFrame handleClickOpen={handleClickOpen}/>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        style={{
          backdropFilter: "blur(1rem)",
          backgroundColor: "rgba(200, 200, 200, 0.5)",
        }}
        PaperProps={{
          style: {
            background: "rgba(223, 249, 255, 0.78)",
            backdropFilter: "blur(5px)",
            borderRadius: "2rem",
            padding: "1rem",
          },
        }}
      >
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          alignItems: "center",
          textAlign: "center",
        }}>
          <Typography variant={isLG ? "h3" : "h4"} style={{padding: "1rem"}}>
            {t("header.dlg.header")}
          </Typography>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: isLG ? "row" : "column",
            paddingTop: "2rem",
            justifyContent: "space-between",
            maxWidth: "27rem",
          }}>
            <button
              onClick={() => setUserType(LandingUserType.CLIENT)}
              style={{
                margin: "0.5rem",
                padding: "0.5rem 2rem",
                borderRadius: "1rem",
                color: userType === LandingUserType.CLIENT ? "white" : "black",
                border: userType === LandingUserType.CLIENT ? "2px solid white" : "2px solid black",
                background: userType === LandingUserType.CLIENT
                  ? "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)"
                  : "#ffffff00",
              }}
            >
              <Typography variant={"body1"}>
                {t("header.dlg.client-btn")}
              </Typography>
            </button>
            <button
              onClick={() => setUserType(LandingUserType.ARTIST)}
              style={{
                margin: "0.5rem",
                padding: "0.5rem 2rem",
                borderRadius: "1rem",
                color: userType === LandingUserType.ARTIST ? "white" : "black",
                border: userType === LandingUserType.ARTIST ? "2px solid white" : "2px solid black",
                background: userType === LandingUserType.ARTIST
                  ? "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)"
                  : "#ffffff00",
              }}
            >
              <Typography variant={"body1"}>
                {t("header.dlg.artist-btn")}
              </Typography>
            </button>
          </div>
          <input
            type="text"
            placeholder="Email"
            className="email-input"
            style={{width: "100%", maxWidth: "26rem", marginTop: "1rem"}}
            onChange={e => setEmail(e.target.value)}
            onBlur={() => setEmailTouched(true)}
          />
          <div style={{color: "#d90909", height: "1rem"}}>
            <Typography variant={"body2"}>
              {!isEmailFieldValid && isEmailTouched ? t("header.dlg.email-invalid") : ""}
            </Typography>
          </div>
          <div style={{paddingTop: "1rem"}}>
            <button
              disabled={isSendDisabled}
              style={{
                margin: "0.5rem",
                padding: "0.5rem 2rem",
                borderRadius: "1rem",
                color: isSendDisabled ? "gray" : "white",
                border: "none",
                background: isSendDisabled ? "#bfbfbf" : "linear-gradient(180deg, #44B5CE 0%, #1C778C 100%)",
              }}
              onClick={onSubmit}>
              <Typography variant={"body1"}>
                {t("header.dlg.send-btn")}
              </Typography>
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default HomePage;
