enum BrowserRoute {
    ROOT = '/',
    HOME = '/home',
    ABOUT = '/about',
    PRESENTATION = '/presentation',
    FAQs = '/faqs',
    LINKED_IN = 'https://www.linkedin.com/company/glamlab',
    INSTAGRAM = 'https://www.instagram.com/glamlabhost'
}

export default BrowserRoute;
