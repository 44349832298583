import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18next/locales/en.json';
import ru from './i18next/locales/ru.json';
import { load } from './utils/local-storage-utils';

const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: load<string>('LANG') || 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
