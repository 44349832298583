import React from "react";
import ReactDOM from "react-dom/client";
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import "@fontsource/urbanist";
import "@fontsource/urbanist/700.css";
import "./i18n";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import ApplicationRoutes from "./components/markup/ApplicationRoutes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const theme = createTheme({
  typography: {
    fontFamily: [
      "Urbanist",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\"",
    ].join(","),
    h1: {
      fontSize: "3.125rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1.08rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1.5rem",
    },
    body2: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    subtitle2: {
      fontSize: "0.75rem",
    },
    caption: {
      fontSize: "1.75rem",
    },
  },
});

root.render(
  <React.StrictMode>
    <CssBaseline/>
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_URL_ROOT}>
        <ApplicationRoutes/>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
