import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import TopNavBar from "../TopNavBar";
import { useTopNavBarSize } from "../../../hooks";


export interface OutletContextProps {
  [key: string]: React.MutableRefObject<any>;
}

const App = () => {
  const describeClientRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);
  const describeMasterRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);

  const homeRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);
  const aboutRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);
  const keepInTouchRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);
  const FAQsRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);
  const missionRef: React.MutableRefObject<Element | null> = useRef<Element | null>(null);

  return (
    <>
      <TopNavBar
        homeRef={homeRef}
        aboutRef={aboutRef}
        keepInTouchRef={keepInTouchRef}
        FAQsRef={FAQsRef}
        missionRef={missionRef}
      />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          pt: useTopNavBarSize(),
          width: "100%",
          // background: 'linear-gradient(90deg, rgba(186,217,229,1) 0%, rgba(202,224,228,1) 50%, rgba(214,232,227,1) 100%);'
        }}
        component="main"
      >
        <Outlet context={{
          describeClientRef,
          describeMasterRef,
          homeRef,
          aboutRef,
          keepInTouchRef,
          FAQsRef,
          missionRef,
        }}/>
      </Box>
    </>
  );
};

export default App;
