import { Container, Typography } from "@mui/material";
import downloadApple from "./DownloadApple.svg";
import downloadGoogle from "./DownloadGoogle.svg";
import phones from "./HeaderPhonesWithBG.png";
import "./HeaderFrame.css";
import React, { FC } from "react";
import { useBiggerThanLG } from "../../../../hooks";
import { LandingPlatform, LandingUserType } from "../../../../service/LandingFeedbackService";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { OutletContextProps } from "../../../markup/App/App";

interface HeaderFrameProps {
  handleClickOpen: (platform: LandingPlatform, userType?: LandingUserType) => () => void;
}

const HeaderFrame: FC<HeaderFrameProps> = ({
 handleClickOpen,
}) => {
  const {t} = useTranslation();
  const isLG = useBiggerThanLG();
  const {homeRef} = useOutletContext<OutletContextProps>();

  return (
    <>
      <div style={{position: isLG ? "absolute" : "unset", width: "100%", zIndex: -1}} ref={homeRef}>
        <Container component="div" maxWidth="lg">
          <div style={{display: "flex", flexDirection: "row"}}>
            {isLG && <div style={{width: "40%"}}/>}
            <div style={{
              width: isLG ? "60%" : "100%",
            }}>
              <img src={phones} style={{
                width: isLG ? "120%" : "100%",
              }}/>
            </div>
          </div>
        </Container>
      </div>
      <Container component="div" maxWidth="lg">
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingBottom: isLG ? "10rem" : "3rem",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: isLG ? "55%" : "100%",
            paddingRight: "2rem",
          }}>
            <div style={{paddingTop: isLG ? "6rem" : "2rem", color: "#44B5CE"}}>
              <Typography variant={isLG ? "h2" : "h5"}>
                {t("header.first-header")}
              </Typography>
            </div>
            <div style={{paddingTop: "2rem"}}>
              <Typography variant={isLG ? "body1" : "body2"}>
                {t("header.subheader-first-part")}
                <span style={{color: "#298CA2"}}>{t("app.name")}</span>
                {t("header.subheader-second-part")}
              </Typography>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: isLG ? "6rem" : "2rem",
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "2rem",
                }}>
                  <Typography variant={isLG ? "subtitle1" : "subtitle2"}>
                    {t("header.for-artists")}
                  </Typography>
                  <div>
                    <img src={downloadApple} style={{height: isLG ? "4rem" : "3rem", cursor: "pointer"}}
                         onClick={handleClickOpen(LandingPlatform.IOS, LandingUserType.ARTIST)}/>
                  </div>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <Typography variant={isLG ? "subtitle1" : "subtitle2"}>
                    {t("header.for-clients")}
                  </Typography>
                  <div>
                    <img src={downloadApple} style={{height: isLG ? "4rem" : "3rem", cursor: "pointer"}}
                         onClick={handleClickOpen(LandingPlatform.IOS, LandingUserType.CLIENT)}/>
                  </div>
                </div>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "2rem",
              }}>
                <Typography variant={isLG ? "subtitle1" : "subtitle2"}>
                  {t("header.stay-in-touch")}
                </Typography>
                <div>
                  <img src={downloadGoogle} style={{height: isLG ? "4rem" : "3rem", cursor: "pointer"}}
                       onClick={handleClickOpen(LandingPlatform.ANDROID)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeaderFrame;