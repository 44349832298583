import axios from "axios";
const {
  REACT_APP_PROTOCOL: protocol,
  REACT_APP_SERVER_ADDRESS: serverAddress,
  REACT_APP_SERVER_PORT: serverPort,
} = process.env;
class LandingFeedbackService {

  private root = `${protocol}://${serverAddress}:${serverPort}`
  sendFeedback = async (dto: LandingFeedbackCreateDto) => {
    const response = await axios
      .post<LandingFeedbackDto>(`${this.root}/landing-feedback`, dto);
    return response.data
  };

  subscribeToUpdates = async (dto: LandingSubscriptionCreateDto) => {
    const response = await axios
      .post<LandingSubscriptionDto>(`${this.root}/landing-subscription`, dto);
    return response.data;
  };
}

export interface LandingFeedbackCreateDto {
  email: string;
  feedback: string;
}

export interface LandingFeedbackDto {
  id: number;
  email: string;
  feedback: string;
}

export interface LandingSubscriptionDto {
  id: number;
  email: string;
  userType: LandingUserType;
  platform: LandingPlatform;
}

export interface LandingSubscriptionCreateDto {
  email: string;
  userType: LandingUserType;
  platform: LandingPlatform;
}

export enum LandingUserType {
  ARTIST = 'ARTIST',
  CLIENT = 'CLIENT',
}

export enum LandingPlatform {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export default new LandingFeedbackService();